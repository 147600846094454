
import { DxPopup, DxToolbarItem } from "devextreme-vue/popup";
import IntegerControl from "@/components/controls/base/IntegerControl.vue";
import { defineComponent } from "vue";

export default defineComponent({
  components: {
    DxPopup,
    DxToolbarItem,
    IntegerControl,
  },
  props: {
    planId: { required: true },
  },
  methods: {
    show(visible: boolean, count: any) {
      if (visible) {
        this.count = count;
        this.maxCount = count;
      }
      this.popupVisible = visible;
    },
    onApply(): void {
      this.show(false);
      this.$emit("update-cargoRolls", this.count);
    },
    onCancel(): void {
      this.show(false);
    },
  },
  data() {
    return {
      count: 0,
      maxCount: 0,
      popupVisible: false,
      okButtonOptions: {
        text: "ОК",
        onClick: this.onApply,
      },
      cancelButtonOptions: {
        text: "Отмена",
        onClick: this.onCancel,
      },
      selectedValues: {},
      properties: [],
    };
  },
});
